<template>
  <v-dialog :value="isShown" persistent max-width="400">
    <v-card class="pb-5 pb-sm-8">
      <v-card-title class="d-block global-break-normal pb-5">
        <close-button right absolute @click="$emit('close-dialog')" />

        <div class="d-flex justify-center text-center font-weight-medium black--text pt-8 pr-5">
          {{ title }}
        </div>
      </v-card-title>

      <v-card-actions v-if="isUnsubscriptionBtn" class="justify-center">
        <c-btn
          large
          depressed
          color="secondary"
          class="py-3 px-10 black--text"
          :label="$t('main.no')"
          @click="$emit('close-dialog')"
        />

        <c-btn
          large
          depressed
          class="py-3 px-10"
          color="primary"
          :label="$t('main.yes')"
          @click="$emit('auto-payment-unsubscription')"
        />
      </v-card-actions>

      <v-card-actions v-else class="d-flex justify-center">
        <c-btn
          large
          class="py-3 px-10"
          color="primary"
          :label="$t('main.update')"
          @click="$emit('auto-payment-update')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CloseButton from '@/components/CloseButton.vue'

  export default {
    name: 'AutoPaymentDialog',
    components: {
      CBtn,
      CloseButton
    },
    props: {
      isUnsubscriptionBtn: {
        type: Boolean,
        default: false
      },
      isShown: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    }
  }
</script>
